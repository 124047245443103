"use client";

import { useEffect } from "react";

export default function Theme({ theme = "Generic" }) {
  useEffect(() => {
    document.body.classList.add(`theme-${theme?.toLowerCase() || "generic"}`);
    return () => {
      document.body.classList.remove(
        `theme-${theme?.toLowerCase() || "generic"}`
      );
    };
  }, [theme]);
  return null;
}
